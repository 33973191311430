.wrapper {
  margin: 100px 0;
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6rem;
}

@media (max-width: 400px) {
  .contentWrapper {
    justify-content: center;
  }
}

.textWrapper {
  flex: 1;
  max-width: 600px;
  min-width: 200px;
}

.title {
  font-size: 2em;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 1.3em;
  margin: 0;
  font-style: italic;
}

.servicesTitle {
  margin-bottom: 0;
}

.servicesList {
  margin-top: 5px;
}

.image {
  border-radius: 20px;
  width: clamp(300px, 70vw, 500px);
}
