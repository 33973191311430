.wrapper {
  position: relative;
}

.module {
  background-color: #fff;
  position: relative; /* Ensure the main element establishes a stacking context */
  z-index: 2;
  margin: 30px 0;
  padding: 50px clamp(0px, 2vw, 50px);
}

.form {
  margin: 0 auto;
}

.formBackground {
  position: absolute;
  width: calc(100% - 90px);
  height: calc(100% - 50px);
  margin: 70px 120px 0;
  background-image: radial-gradient(
    var(--PRIMARY-COLOR),
    var(--QUATERNARY-COLOR),
    var(--PRIMARY-COLOR)
  );
  border-radius: 30px;
  z-index: 1;
}

@media (max-width: 400px) {
  .formBackground {
    display: none;
  }
}
