.navWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
  border-radius: 20px;
}

.button {
  background-color: #fff;
}
