.quickbooks_proadvisor_badge_st0 {
  fill: #ffffff;
}

.quickbooks_proadvisor_badge_st1 {
  fill: #f4f5f9;
}

.quickbooks_proadvisor_badge_st2 {
  fill: #d4d8dd;
}

.quickbooks_proadvisor_badge_st3 {
  fill: #00a14f;
}

.quickbooks_proadvisor_badge_st4 {
  fill: #00ad4e;
}

.quickbooks_proadvisor_badge_st5 {
  fill: #009e4e;
}

.quickbooks_proadvisor_badge_st6 {
  fill: #57b947;
}
