.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  color: var(--TERTIARY-COLOR);
  outline-offset: -2px;
  transition: transform ease 0.1s;
}

.button:hover {
  outline-offset: 1px;
  transform: scale(1.06);
}

.primary {
  outline: 1px solid var(--PRIMARY-COLOR);
  background-color: var(--PRIMARY-COLOR);
}

.primary:hover {
  background-color: #fdca00;
}

.secondary {
  outline: 1px solid var(--SECONDARY-COLOR);
  background-color: var(--SECONDARY-COLOR);
}

.secondary:hover {
  background-color: #bacaeac1;
}
