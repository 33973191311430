.wrapper {
  margin: 0 var(--LATERAL_SPACING);
  padding-top: 100px;
}

.descriptionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  max-width: 600px;
  font-size: 2.5em;
  margin-bottom: 50px;
  font-weight: 500;
}

.growing {
  font-weight: bold;
  margin-right: 10px;
}

.easy {
  color: var(--PRIMARY-COLOR);
  margin-left: 10px;
  font-style: italic;
}

.description {
  max-width: 700px;
  display: unset;
  font-size: 18px;
}
