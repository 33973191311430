.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownMenu {
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate3d(-80px, 42px, 0px);
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  border-radius: 5px;
  min-width: 115px;
}

.item {
  padding: 8px 12px;
  cursor: pointer;
}

.item:hover {
  background-color: #f0f0f0;
}

.disabledItem {
  background-color: #f0f0f0;
  cursor: default;
}
