.content {
  margin: 0 var(--LATERAL_SPACING);
  padding-top: 100px;
}

.ownerBackgroundSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5rem;
}

.textWrapper {
  min-width: 200px;
  flex: 1;
}

@media (max-width: 1000px) {
  .textWrapper {
    width: 80vw;
  }
}

.title {
  font-size: 2em;
  margin-top: 0;
}

.ownerDescription {
  margin-top: 20px;
  font-size: 1.4em;
  font-weight: 500;
}

.action {
  margin-top: 40px;
  padding: 20px 30px;
}

.ownerPicture {
  width: clamp(200px, 40vw, 600px);
  border-radius: 10px;
}

@media (max-width: 1000px) {
  .ownerPicture {
    width: 70vw;
  }
}

.certSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
