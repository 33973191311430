.messageTitle {
  font-size: 3em;
  margin-bottom: 0;
}

.subTitle {
  font-size: 1.3em;
  margin: 10px 0 30px;
}

.formSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 70px;
  margin: 0 var(--LATERAL_SPACING);
}

.formSectionText {
  margin-top: 40px;
  text-align: center;
}

.contactMethods {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: auto;
  max-width: 600px;
}

.phoneWrapper {
  margin-top: 50px;
}

.phoneNumber {
  color: var(--PRIMARY-COLOR);
  text-decoration: none;
}

.phoneNumber:hover {
  color: var(--TERTIARY-COLOR);
}

.emailWrapper {
  margin-top: 50px;
}

.email {
  color: var(--PRIMARY-COLOR);
  text-decoration: none;
}

.email:hover {
  color: var(--TERTIARY-COLOR);
}
