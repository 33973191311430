.wrapper {
  background-color: var(--PRIMARY-COLOR);
  color: var(--TERTIARY-COLOR);
  padding: 40px var(--LATERAL_SPACING);
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}

.businessDescription {
  max-width: 400px;
  margin-left: 20px;
  margin-top: 0;
}

.rightLayout {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.linksTitle {
  margin-right: 15px;
}

.navLinks {
  max-width: 200px;
  justify-content: flex-end;
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

.socialsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  margin: 10px 15px 0 0;
}

.socialsWrapper,
a {
  color: var(--TERTIARY-COLOR);
}

.contactMessageWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 40px;
  text-align: end;
}

.contactMessage {
  max-width: 200px;
}
