.wrapper {
  cursor: pointer;
  transition: transform ease 0.1s;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding-bottom: 10px;
}

.wrapper:hover {
  transform: scale(1.06);
}

.title {
  font-size: 1.25em;
  margin: 30px 0 20px 0;
}

.image {
  margin: -50px;
  margin-bottom: 0px;
  border-radius: 20px 20px 0 0;
}
