.wrapper {
  margin: 100px 0;
}

.title {
  font-size: 2.5em;
  margin-bottom: 50px;
}

.svgWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: center;
}

@media (max-width: 400px) {
  .svgWrapper {
    justify-content: center;
  }
}

.assetWrapper {
  margin: 0 30px;
}

.image {
  width: 130px;
}

.gusto {
  width: 235px;
}

.cpaImage {
  width: 235px;
}
