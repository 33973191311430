.wrapper {
  z-index: 100;
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.text {
  padding: 5px 30px;
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  font-weight: 500;
}

.button {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.45);
  padding: 13px 20px;
}
