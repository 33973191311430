.titleBanner {
  position: absolute;
  top: 0;
  max-width: 750px;
  margin: clamp(7rem, 18vw, 20rem) 0;
  padding: 0 1rem;
  color: #fff;
  border-radius: 20px;
}

.titleHeader {
  font-size: clamp(1rem, 3.8vw, 9rem);
}

.titleDescription {
  font-size: clamp(1rem, 1.5vw, 4rem);
}

.content {
  margin: 0 var(--LATERAL_SPACING);
}

.serviceSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5rem;
}

@media (max-width: 400px) {
  .serviceSection {
    flex-direction: column;
    align-items: center;
  }
}

.descriptionWrapper {
  max-width: 600px;
  min-width: 200px;
  margin-bottom: 40px;
}

.title {
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
}

.subtitle {
  font-size: 1.5rem;
}

.button {
  margin-top: 50px;
}
