.loadingOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--PRIMARY-COLOR);
  color: var(--TERTIARY-COLOR);
  z-index: 999;
  overflow: hidden;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 35px;
  font-weight: 800;
  overflow: hidden;
}

.content span {
  margin-right: 10px;
}
